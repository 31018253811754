<template>
  <div class="registration">
    <form class="registration__form" action="" @submit.prevent="registrationSubmit">
      <div class="registration__item">
        <label for="">Email</label>
        <b-input
          v-model="registration.email"
          type="email"
          class="registration__input main-input"
          placeholder="Введите свой e-mail"
          required
        />
      </div>
      <div class="registration__item">
        <label for="">Пароль</label>
        <b-input
          v-model="registration.password"
          :type="iconShow ? 'password' : 'text'"
          minlength="3"
          class="registration__input-password main-input"
          placeholder="Придумайте пароль"
          required
        />
        <a>
          <b-icon
            class="registration__input-icon"
            :icon="iconShow ? 'eye' : 'eye-slash'"
            @click="iconShow = !iconShow"
          />
        </a>
      </div>
      <div class="registration__item">
        <b-input
          v-model="passwordRepeat"
          :type="iconShow ? 'password' : 'text'"
          minlength="3"
          class="registration__input-password main-input"
          placeholder="Повторите пароль"
          required
        />
      </div>
      <button type="submit" class="registration__btn main-btn">
        Продолжить регистрацию
        <b-icon class="registration__btn-icon" icon="arrow-right" />
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      iconShow: true,
      registration: {
        email: "",
        password: "",
      },
      passwordRepeat: "",
    };
  },
  methods: {
    registrationSubmit() {
      if (this.passwordRepeat === this.registration.password) {
        this.$store.commit("setRegistration", this.registration);
        this.$router.push("/auth/registration-addition");
      } else {
        alert("парол подверждение неправыльный");
      }
    },
  },
};
</script>

<style scoped>
.registration {
  margin: 40px 0 30px;
  width: 100%;
}
.registration__item {
  width: 100%;
  position: relative;
}
.registration__input-password {
  margin: 5px 0 10px;
}
.registration__btn {
  position: relative;
  margin: 20px 0 0 0;
}
.registration__btn-icon,
.registration__input-icon {
  position: absolute;
  right: 20px;
}
.registration__input-icon {
  top: 55%;
}
</style>
